import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ListingCard, IconChevron } from '../../../components';

import css from './OtherListings.module.css';
import { FormattedMessage } from 'react-intl';

const OtherListings = ({ listings, showMoreLink }) => {
  const scrollContainerRef = useRef(null);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  return (
    <div className={css.similarListingsContainer}>
      <div className={css.similarListingsWrapper}>
        <button className={css.scrollButton} onClick={handleScrollLeft}>
          <IconChevron className={css.prevIcon} />
        </button>
        {listings?.length === 0 && (
          <p className={css.noListingsYet}>
            <FormattedMessage id="OtherListings.noListings" />
          </p>
        )}

        <div className={css.similarListingsGrid} ref={scrollContainerRef}>
          {listings.map(listing => (
            <ListingCard
              key={listing.id.uuid}
              className={css.similarListingCard}
              listing={listing}
            />
          ))}
          {showMoreLink}
        </div>
        <button className={css.scrollButton} onClick={handleScrollRight}>
          <IconChevron className={css.nextIcon} />
        </button>
      </div>
    </div>
  );
};

OtherListings.propTypes = {
  listings: PropTypes.array.isRequired,
  title: PropTypes.string,
  showMoreLink: PropTypes.node,
};

OtherListings.defaultProps = {
  showMoreLink: null,
};

export default OtherListings;
