import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import { getYoutubeIdFromURL } from '../../util/urlHelpers';
import { injectIntl } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import { YoutubeEmbed } from '../PageBuilder/Primitives/YoutubeEmbed';

const getYoutubeThumbnail = url => {
  const videoId = getYoutubeIdFromURL(url);
  if (videoId) {
    return `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`;
  }
  return '';
};

const SectionGallery = props => {
  const { listing, variantPrefix, intl } = props;
  const images = listing.images;
  const videoLink = listing.attributes.publicData.videoLink;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  const videoItem = videoLink
    ? {
        embedUrl: videoLink[0],
        original: '',
        alt: intl.formatMessage({ id: 'SectionGallery.videoAltText' }),
        thumbAlt: intl.formatMessage({ id: 'SectionGallery.videoThumbnailAltText' }),
        thumbnail: {
          id: 'video-thumbnail',
          type: 'image',
          attributes: {
            variants: {
              'listing-card': {
                height: 200,
                name: 'listing-card',
                url: getYoutubeThumbnail(videoLink[0]),
                width: 400,
              },
            },
          },
        },
        isVideo: true,
        renderElement: <YoutubeEmbed youtubeVideoId={getYoutubeIdFromURL(videoLink[0])} />,
      }
    : null;

  return (
    <section className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        videoItem={videoItem}
      />
    </section>
  );
};

export default injectIntl(SectionGallery);
